import React from 'react';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import Vimeo from 'react-vimeo';
import { youTubeParser } from '../utils/helpers';

import '../assets/sass/video.scss';

class Video extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      active: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { active } = this.state;
    this.setState({
      active: !active,
    });
  }

  render() {
    const { video } = this.props;
    const { active } = this.state;
    const content = active
      ? <VideoEmbed video={video} />
      : <VideoThumb video={video} handleClickState={this.handleClick} />;
    return (
      <div className="video">{content}</div>
    );
  }
}

const VideoThumb = ({ video, handleClickState }) => {
  let content;
  if (video.provider_name === 'Vimeo') {
    content = (
      <div className="light-video-embed" data-id={video.media_id}>
        <img src={video.thumbnail_url} alt="" />
        <div
          className="play"
          onClick={handleClickState}
          onKeyPress={handleClickState}
          role="button"
          aria-label="Play"
          tabIndex={0}
        />
      </div>
    );
  } else {
    content = (
      <div className="light-video-embed" data-id={video.embed_url}>
        <img src={video.thumbnail_url} alt="" />
        <div
          className="play"
          onClick={handleClickState}
          onKeyPress={handleClickState}
          role="button"
          aria-label="Play"
          tabIndex={0}
        />
      </div>
    );
  }
  return content;
};

const VideoEmbed = ({ video }) => {
  let id;
  let content;
  if (video.provider_name === 'Vimeo') {
    content = (
      <Vimeo videoId={`${video.media_id}`} autoplay />
    );
  } else {
    id = youTubeParser(video.embed_url);
    content = (
      <YouTube videoId={id} id={id} />
    );
  }
  return content;
};

Video.propTypes = {
  video: PropTypes.object.isRequired,
};

export default Video;
